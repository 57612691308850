const state = {
  loading: null,
  firstLoad: false,
  message: {
    text: null,
    color: null,
    show: null,
    timeout: parseInt(process.env.VUE_APP_MESSAGE_TIMEOUT, 10),
  },
  formErrors: null,
  token: null,
}

const getters = {
  token: state => state.token,
}

const actions = {}

const mutations = {
  setLoading (state, value) {
    if (state.formErrors && value) {
      state.formErrors = null
    }
    state.loading = value
  },
  setMessage (state, message) {
    state.message.text = message.text
    state.message.color = message.color
    state.message.timeout = message.timeout
    state.message.show = true
  },
  setFormErrors (state, errors) {
    state.formErrors = errors
  },
  setToken (state, token) {
    state.token = token
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

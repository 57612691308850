export default {
  title: {
    main: 'Generación de reporte',
    unauthorized: 'No autorizado',
  },
  input: {
    emailLabel: 'Correo electrónico',
    required: 'Correo electrónico requerido',
    emailInvalid: 'Correo electrónico no es valido',
  },
  button: {
    triggerLabel: 'Generar',
  },
  datePicker: {
    startDateLabel: 'Desde',
    endDateLabel: 'Hasta',
  },
  loading: {
    loadingLabel: 'Cargando...',
  },
  error: {
    errorMessage: 'Ha ocurrido un error',
  },
  errorMessage: {
    page_unauthorized: 'Error: (401) no autorizado',
    page_unauthorized_description:'El token de seguridad ha caducado, la acción ha sido cancelada.',
  },
  notification: {
    notificationLabel: 'Notificación',
    notificationError: 'Ha ocurrido un error',
    notificationSuccess: 'Solicitud de reporte enviada. Se enviará el resultado al correo indicado',
    notificationEmailEmpty: 'Por favor ingresa tu correo electrónico',
  },
}
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

const locale = (navigator.language || navigator.userLanguage).split('-')[0] === 'es' ? 'es' : 'en'

export default new Vuetify({
  lang: {
    locales: { en, es },
    current: locale,
  },
  locale,
  theme: {
    themes: {
      light: {

        primary: {
          lighten2: '#E6FFFF',
          lighten: '#241DBA',
          base: '#00D7D7',
          darken1: '#009999',
          darken2: '#003333',
        },
        secondary: {
          lighten2: '#EAE9FC',
          lighten1: '#9591EE',
          darken1: '#16116E',
          darken2: '#09072C',
          base: '#040729',
        },
        'custom-grey': {
          lighten1: '#e7e7e7',
          lighten2: '#f5f5f5',
          lighten3: '#f8f8f8',
          lighten4: '#D8D8D8',
          lighten5: '#808E95',
          darken5: '#37474F',
          base: '#455A64',
        },
        accent: '#241DBA',
        error: '#D32F2F',
      },
    },
  },
  options: { customProperties: true },
})

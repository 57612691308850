import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locale/en'
import es from './locale/es'

Vue.use(VueI18n)
const messages = {
  en,
  es,
}
const i18n = new VueI18n({
  locale: (navigator.language || navigator.userLanguage).split('-')[0] === 'es' ? 'es' : 'en',
  messages,
})

export default i18n
export default {
  title: {
    main: 'Report generation',
    unauthorized: 'Unauthorized',
  },
  input: {
    emailLabel: 'E-mail',
    required: 'E-mail is required',
    emailInvalid: 'E-mail must be valid',
  },
  button: {
    triggerLabel: 'Submit',
  },
  datePicker: {
    startDateLabel: 'Since',
    endDateLabel: 'Until',
  },
  loading: {
    loadingLabel: 'Loading...',
  },
  error: {
    errorMessage: 'Error',
  },
  errorMessage: {
    page_unauthorized: 'Error: (401) unauthorized',
    page_unauthorized_description:'The security token has expired, the action has been cancelled.',
  },
  notification: {
    notificationLabel: 'Notification',
    notificationError: 'Something went wrong',
    notificationSuccess: 'Report request sent. The result will be sent to the indicated email',
    notificationEmailEmpty: 'You need to provide an E-mail address',
  },
}
